import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Hero from "../components/hero"
import ArrowRight from "../components/svg/arrowRight"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import Testimonial from "../components/testimonial"
import Cta from "../components/cta"

const GsesPage = () => (
    <Layout>
        <Seo 
            title="Sustainable software development case study" 
            description="The software development services completed for this Sustainable Energy application."
        />

        <Hero
            h1="Solar energy management and analytics application case study"  
            h2="Global Sustainable Energy Solutions"
        />

        <section className="bg-noise gses section-pad-sm">
            <div className="container-lg" id="anchor">
                <div className="row">
                    <StaticImage 
                        src="../images/gses-main.png"
                        alt="Screens of the GSES web application"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        className="porti-top-img"
                        placeholder="none"
                        backgroundcolor="white"
                        layout="fullWidth"
                        quality="100%"
                    />
                </div>
            </div>
        </section>

        <section className="section-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="porti-wrapper">
                        <div className="cols">
                            <div className="col-26">
                                <div className="sticky-porti-menu">
                                    <button onClick={() => scrollTo('#bg')} className="btn">
                                        Background
                                    </button>
                                    <button onClick={() => scrollTo('#services')} className="btn">
                                        Services
                                    </button>
                                    <button onClick={() => scrollTo('#work')} className="btn">
                                        The work
                                    </button>
                                    <button onClick={() => scrollTo('#testimonial')} className="btn">
                                        Testimonial
                                    </button>
                                    <button onClick={() => scrollTo('#project')} className="btn">
                                        View project
                                    </button>
                                </div>
                            </div>
                            <div className="col-67 long-text">
                                <div id="bg" className="porti-text-section">
                                    <h3>Background</h3>
                                    <p>GSES provide engineering, training and consulting services in the solar energy space.</p>
                                    <p>GSES approached Launch Lab to develop a web application that can be used by local and state governments to manage and monitor the performance of their photovoltaic systems.</p>
                                </div>
                                <div id="services" className="porti-text-section">
                                    <h4>Services</h4>
                                    <Link to="/web-design/" className="btn btn-lg btn-line">Product design</Link>
                                    <Link to="/react-developers-sydney-canberra/" className="btn btn-lg btn-line">React development</Link>
                                    <Link to="/web-development/" className="btn btn-lg btn-line">Application development</Link>
                                    <Link to="/aws-infrastructure/" className="btn btn-lg btn-line">AWS infrastructure</Link>
                                </div>
                                <div id="work" className="porti-text-section">
                                    <h4>The work</h4>
                                    <p>We designed and developed a web application using AWS CDK, AWS Lambda, Python and React that takes data from solar panels, owned by local and state governments, and enables them to access performance and energy / cost saving analytics.</p>
                                    <p>The application also provides these organisations with insights on how to get the most value out of their solar panels.</p>
                                    <p>The feedback on the product has been extremely positive with the application having been <strong>nominated for a sustainable software award</strong>.</p>
                                </div>
                                <div id="testimonial" className="porti-text-section">
                                    <h4>Testimonial</h4>
                                    <Testimonial
                                        excerpt="Professional"
                                        quote="“Launch Lab are extremely competent and professional and are also pleasure to work with. The team at Launch Lab understood our needs and were able to deliver precisely as promised. I also greatly appreciated that we were able to get right into development without any workshops or sprints that were required by other developers. Instead we had a few meetings to discuss our scoping documents and then Launch Lab got right into the task at hand. I highly recommend Launch Lab!”"
                                        name="Christopher Martell"
                                        title="Director of Operations and Engineering, GSES"
                                    />
                                </div>
                                <div id="project" className="porti-text-section">
                                    <h5>View project</h5>
                                    <p>The GSES application is not publicly available. To find out more about GSES please visit their <a href="https://www.gses.com.au/">website</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>
        
        <Cta 
            title="Start your web development project"
            text="Get an obligation free web app development quote from an experienced local Australian development team."
        />
    </Layout>
)

export default GsesPage